.roakSq_container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
}

.roakSq_container > div {
  flex-grow: inherit;
  margin-top: 25px;
}

.roakSq_loadingLogo {
  height: 60px;
}

.roakSq_errorBody {
  padding-left: 30px;
  padding-right: 30px;
  font-size: 16px;
  line-height: 30px;
}

.roakSq_hoverStyle:hover {
  background-image: linear-gradient(227deg, #1f4ddb 0%, #44c4ad 100%) !important;
}

/*# sourceMappingURL=Redirector.19e37a8b.css.map */
